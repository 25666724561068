<script>
/* eslint vuejs-accessibility/anchor-has-content: 'off' */
/* eslint vue/no-reserved-component-names: 'off' */
import Button from 'primevue/button';

export default {
  name: 'HeroModule',
  components: {
    Button,
  },
  data() {
    return {
      social: [
        'globe',
        'facebook',
        'instagram',
        'google',
        'linkedin',
        'twitter',
        'youtube',
        'whatsapp',
      ],
      profile: {
        rot13_phone: '+46768187170',
        rot13_email: 'uryyb@creunyyebf.pbz',
        globe: 'https://perhallros.com/',
        linkedin: 'https://www.linkedin.com/in/per-hallros/',
        instagram: 'https://www.instagram.com/perhallros/',
        facebook: 'https://www.facebook.com/per.hallros/',
      },
    };
  },
  methods: {
    rot13: (message) => {
      const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const b = 'nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM';
      return message.replace(/[a-z]/gi, (c) => b[a.indexOf(c)]);
    },
  },
};
</script>

<template>
  <div class="p-grid-nogutter flex justify-content-center align-items-center" style="height: 100%; min-height: 100vh">
    <div class="container">
      <div class="logo" />
      <div class="mt-4 flex justify-content-center">
        <span><a v-if="profile.rot13_phone" :href="rot13('gry:' + profile.rot13_phone)" class="ml-1 mr-1">
          <Button icon="pi pi-phone" type="button" class="p-button-icon-only p-button-rounded p-button-text" />
        </a></span>
        <span><a v-if="profile.rot13_email" :href="rot13('znvygb:' + profile.rot13_email)" class="ml-1 mr-1">
          <Button icon="pi pi-envelope" type="button" class="p-button-icon-only p-button-rounded p-button-text" />
        </a></span>
        <span v-for="media in social" :key="media">
          <a v-if="profile[media]" :href="profile[media]" class="ml-1 mr-1" target="_blank" rel="noopener noreferrer">
            <Button :icon="'pi pi-' + media" type="button" class="p-button-icon-only p-button-rounded p-button-text" />
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  animation: 1.5s ease-out 0s 1 slideInFromBelow;
}
.logo {
    background-image: url('@/assets/logo_clean.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 20rem;
    height: 20rem;
    position: relative;
}
@keyframes slideInFromBelow {
  0% {
    transform: translateY(50%);
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    display: block;
    opacity: 1;
  }
}
.p-button {
  color: #154988 !important;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-button:focus {
    box-shadow: 0 0 0 0.1rem #154988;
}
.p-button:enabled:hover {
  background: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}
</style>
