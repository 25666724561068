// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyAXZarKtQkNTSAOVBP7x0PCK5EmYt44cdo',
  authDomain: 'perhallros-com.firebaseapp.com',
  projectId: 'perhallros-com',
  storageBucket: 'perhallros-com.appspot.com',
  messagingSenderId: '856022260287',
  appId: '1:856022260287:web:e33f916662d9cd3446368a',
  measurementId: 'G-6SST1N017W',
};

export default firebaseConfig;
